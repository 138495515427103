import type { FC } from 'react'

import { buildQuery } from '~/utils/buildQuery'
import config from '~/utils/config'

import { SignInButton } from './styled'

const signInCallbackQuery = {
  response_type: 'code',
  client_id: config.mtClientId,
  redirect_uri: encodeURI(`${config.siteUrl}/mariana-tek/callback`),
  scope: 'read:account',
  code_challenge: 'coolcode',
  code_challenge_method: 'plain',
}

export const signInCallbackUrl = `${
  config.marianaTekUrl
}/o/authorize?${buildQuery(signInCallbackQuery)}`

interface Props {
  readonly inverted?: boolean
  readonly disabled?: boolean
}

const label = 'Sign in'

const LoginButton: FC<Props> = ({ inverted, disabled }) => {
  if (disabled)
    return (
      <SignInButton disabled inverted={inverted}>
        {label}
      </SignInButton>
    )

  return (
    <SignInButton as="a" href={signInCallbackUrl} inverted={inverted} isLoading>
      {label}
    </SignInButton>
  )
}

export default LoginButton
