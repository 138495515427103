import Head from 'next/head'

import Layout from '~/components/layout'
import LoginPrompt from '~/components/login'

const LoginPage = () => (
  <Layout fullscreenContent>
    <Head>
      <title>Login Required | Barry&rsquo;s</title>
    </Head>
    <LoginPrompt />
  </Layout>
)

export default LoginPage
