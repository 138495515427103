import SignInButton from './login-button'
import { Page, Title, Paragraph } from './styled'

const LoginPrompt = () => (
  <Page narrow>
    <Title>Login required</Title>
    <Paragraph>Please sign in to view this page.</Paragraph>
    <SignInButton />
  </Page>
)

export default LoginPrompt
