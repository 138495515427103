/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
// import 'node-fetch'
import * as Sentry from '@sentry/nextjs'
import type { NextPageContext } from 'next'
import { setCookie } from 'nookies'

import type { serialize } from '~/containers/dashboard/questionnaire/data'
import { buildQuery } from '~/utils/buildQuery'
import { offersToRewardsBenefits } from '~/utils/offersToRewardsBenefits'

import config from './config'
import { cookies } from './cookies'

const Api = (ctx?: NextPageContext) => {
  const request = <T>(
    endpoint: string,
    method = 'GET',
    body?: object,
    options = {},
    headers = {},
    apiRoot = config.apiRoot
  ): Promise<T> =>
    Promise.race([
      new Promise((resolve, reject) =>
        fetch(`${apiRoot}${endpoint}`, {
          headers,
          method,
          ...options,
          ...(body ? { body: JSON.stringify(body) } : null),
        })
          .then(async response => {
            if (response && response.status >= 400 && response.status < 599) {
              const err = await response.json().catch(() => '')

              reject(err)
            } else {
              return await response.json().catch(() => '')
            }
          })
          .then(response => resolve(response))
      ),
      new Promise((_resolve, reject) => {
        setTimeout(() => {
          const err = new Error('Request Timeout')

          Sentry.captureException(err, { extra: { endpoint } })
          reject(err)
        }, 15000)
      }),
    ]) as Promise<T>

  const authorizedRequest = async <T>(
    endpoint: string,
    method = 'GET',
    body?: object,
    options = {},
    headers = {},
    apiRoot = config.apiRoot
  ): Promise<T> => {
    headers = headers || {}

    const accessToken = cookies(ctx).accessToken

    if (accessToken) {
      headers = { ...headers, Authorization: `Bearer ${accessToken}` }
    }

    return await request<T>(
      endpoint,
      method,
      body,
      { ...options, cache: 'reload' },
      headers,
      apiRoot
    )
  }

  // const newPosts = () => request<Post[]>('wp-json/wp/v2/posts')
  const login = (code: string, codeVerifier: string) =>
    request<{ data: { token: string } }>(`/oauth/mariana-tek`, 'POST', {
      code,
      codeVerifier,
    }).then(async response => {
      const { token } = response.data

      setCookie(ctx, 'accessToken', token, {
        path: '/',
        maxAge: 30 * 24 * 60 * 60, // a month
      })

      const data = await request<{ data: User }>(
        '/loyalty/user',
        'GET',
        undefined,
        undefined,
        { Authorization: `Bearer ${token}` }
      ).then(r => ({
        ...r.data,
        ...offersToRewardsBenefits(r.data.offers, r.data.tier.name),
      }))

      // Cookies.set('accessToken', token)
      return { token, user: data }
    })

  const joinLoyalty = () => authorizedRequest('/loyalty/join', 'POST')

  const sendQuestionnaire = (values: ReturnType<typeof serialize>) =>
    authorizedRequest('/loyalty/questionnaire', 'POST', values)

  const updateQuestionnaire = (values: ReturnType<typeof serialize>) =>
    authorizedRequest('/loyalty/questionnaire', 'PATCH', values)

  const optOut = () => authorizedRequest('/loyalty/join', 'DELETE')

  const fetchUser = () =>
    authorizedRequest<{ data: User }>('/loyalty/user').then(response => ({
      ...response.data,
      ...offersToRewardsBenefits(response.data.offers, response.data.tier.name),
    }))

  const fetchPurchases = (since?: number, count = 20) =>
    authorizedRequest<{ data: Purchase[] }>(
      `/loyalty/point-history?${buildQuery({
        ...(since ? { since } : null),
        count,
      })}`
    )

  const fetchClasses = () =>
    authorizedRequest<ClasssesResponse>('/loyalty/classes')

  const fetchChallenges = () =>
    authorizedRequest<ChallengesResponse>('/loyalty/challenges')

  const fetchChallengeDetails = (id: string) =>
    authorizedRequest<ChallengeDetails>(`/fc/challenges/${id}/registration`)

  const fetchTiers = () =>
    request<Tiers>('/loyalty/tiers').then(response => ({
      ...response,
      data: response.data.sort((a, b) => a.points.total - b.points.total),
    }))

  const delay = (valueMs: number) =>
    new Promise(resolve =>
      setTimeout(() => {
        resolve(null)
      }, valueMs)
    )

  return {
    delay,
    login,
    joinLoyalty,
    sendQuestionnaire,
    updateQuestionnaire,
    optOut,
    fetchUser,
    fetchTiers,
    fetchPurchases,
    fetchClasses,
    fetchChallenges,
    fetchChallengeDetails,
  }
}

export default Api
