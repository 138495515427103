import Link from 'next/link'
import type { FC } from 'react'
import { useRef } from 'react'

import Container from '~/components/container'
import { useAppContext } from '~/components/context'
import { Logo } from '~/components/icons/logo'

import ScrollDownButton from './scroll-down-button'
import {
  Header,
  Nav,
  BarrysLogo,
  Menu,
  Intro,
  ButtonLink,
  Chevrons,
} from './styled'

import ActionButton from '../action-button'

interface Props {
  readonly loginDisabled?: boolean
}

const HomepageHeader: FC<Props> = ({ loginDisabled }) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { isNativeApp } = useAppContext()

  const handleScroll = () => {
    const nextSectionElement = sectionRef.current?.nextSibling
      ?.firstChild as HTMLDivElement

    nextSectionElement.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Header ref={sectionRef}>
      <Chevrons />
      <Container>
        <Nav>
          <BarrysLogo />

          {!isNativeApp && (
            <Menu>
              <li>
                <Link href="https://www.barrys.com/" target="_blank">
                  <ButtonLink outline inverted>
                    Buy Classes
                  </ButtonLink>
                </Link>
              </li>
              <li>
                <Link href="https://www.barrys.com/book-now/" target="_blank">
                  <ButtonLink outline inverted>
                    Book Now
                  </ButtonLink>
                </Link>
              </li>
            </Menu>
          )}
        </Nav>

        <Logo />

        <Intro>
          A complimentary membership program inviting our loyal community to
          receive rewards based on points earned.
        </Intro>

        <ActionButton loginDisabled={loginDisabled} />

        <ScrollDownButton onClick={handleScroll} />
      </Container>
    </Header>
  )
}

export default HomepageHeader
