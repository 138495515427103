/* eslint-disable @typescript-eslint/unbound-method */
import isPropValid from '@emotion/is-prop-valid'
import type { AppContext as NextAppContext, AppProps } from 'next/app'
import { Router } from 'next/router'
import { destroyCookie, setCookie } from 'nookies'
import NProgress from 'nprogress'
import { useEffect, useState } from 'react'
import 'nprogress/nprogress.css'
import { StyleSheetManager } from 'styled-components'

import { AppContext } from '~/components/context'
import { GoogleTagManager } from '~/components/google-tag-manager'
import HitNextTier from '~/components/hit-next-tier'
import Notifications from '~/components/notifications'
import Home from '~/containers/homepage'
import LoadingScreen from '~/containers/loading-screen'
import LoginPage from '~/containers/login-page'
import GlobalStyle from '~/styles/global'
import Api from '~/utils/api'
import { cookies } from '~/utils/cookies'
import { isWaitingTier } from '~/utils/isWaitingTier'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', NProgress.start)
Router.events.on('routeChangeComplete', NProgress.done)
Router.events.on('routeChangeError', NProgress.done)

interface CookiesData {
  accessToken?: string
  tier?: string
  isNativeApp: boolean
}

function App({ Component, router, pageProps }: AppProps<CookiesData>) {
  const [isLoading, setIsLoading] = useState(true)
  const [tiers, setTiers] = useState<Tiers>()
  const [user, setUser] = useState<User | null>(null)
  const [error, setError] = useState<ApiError | null>(null)
  const [token, setToken] = useState<string>(pageProps.accessToken ?? '')
  const [showPromotionDialog, setShowPromotionDialog] = useState(false)
  const [isNativeApp] = useState<boolean>(pageProps.isNativeApp)

  const [notifications, setNotifications] = useState<
    AppContextType['notifications']
  >([])

  const fetchTiers = async () => {
    const t = await Api()
      .fetchTiers()
      .catch(err => {
        setError(err)

        return {
          data: [],
          meta: {
            nextMaintenanceDate: '',
          },
        }
      })

    setTiers(t)
  }

  const fetchUser = async () => {
    try {
      if (token && router.pathname !== '/mariana-tek/callback') {
        const u = await Api().fetchUser()

        setUser(u)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('_app error', err)

      if (
        err &&
        (err.code === 'E_UNAUTHORIZED' ||
          (err as Error).message.toLowerCase().includes('forbidden'))
      ) {
        destroyCookie(null, 'accessToken')
        setToken('')
      }
    }
  }

  const addNotification: AppContextType['addNotification'] = arg =>
    setNotifications(prevState => [
      ...prevState,
      {
        id: Date.now(),
        ...(typeof arg === 'string' ? { text: arg } : arg),
      },
    ])

  const removeNotification = (id: number) =>
    setNotifications(prevState => prevState.filter(i => i.id !== id))

  useEffect(() => {
    Promise.all([fetchTiers(), fetchUser()]).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (user?.tier.name && !isWaitingTier(user.tier.name)) {
      const cookieTier = pageProps.tier

      if (!cookieTier) {
        setCookie(null, 'tier', user.tier.name, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60 * 12, // a year
        })
      } else if (cookieTier !== user.tier.name) {
        setCookie(null, 'tier', user.tier.name, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60 * 12, // a year
        })

        setShowPromotionDialog(true)
      }
    }
  }, [user])

  const login = (t: string, u: User | null) => {
    setUser(u)
    setToken(t)
  }

  const logout = () => {
    setUser(null)
    setToken('')
  }

  const getComponentToRender = () => {
    if (
      (!token || error?.code === 'E_UNAUTHORIZED') &&
      !['/', '/mariana-tek/callback'].includes(router.pathname)
    ) {
      return <LoginPage />
    }

    if (isLoading) {
      return <LoadingScreen />
    }

    if (user && isWaitingTier(user.tier.name)) {
      return <Home />
    }

    return <Component />
  }

  // This implements the default behavior from styled-components v5
  function shouldForwardProp(propName, target) {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName)
    }

    // For other elements, forward all props
    return true
  }

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <GlobalStyle />

      <GoogleTagManager>
        <AppContext.Provider
          value={{
            user,
            token,
            notifications,
            tiers: tiers!,
            setToken,
            updateUser: setUser,
            addNotification,
            removeNotification,
            currentTier: tiers?.data.find(
              tier => tier.name === user?.tier.name
            ),
            login,
            logout,
            isNativeApp,
            showPromotionDialog,
          }}
        >
          {getComponentToRender()}
          <Notifications />
          {showPromotionDialog && (
            <HitNextTier onClose={() => setShowPromotionDialog(false)} />
          )}
        </AppContext.Provider>
      </GoogleTagManager>
    </StyleSheetManager>
  )
}

App.getInitialProps = (appContext: NextAppContext) => {
  let isNativeApp = false

  if (appContext.ctx.req) {
    isNativeApp = (appContext.ctx.req.headers['user-agent'] ?? '')
      .toLowerCase()
      .includes('marianatek')
  }

  return {
    pageProps: {
      isNativeApp,
      ...cookies(appContext.ctx),
    },
  }
}

export default App
