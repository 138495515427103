import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { ItemContainer } from './styled'

import { useAppContext } from '../context'

const NotificationItem: FC<NotificationType> = ({
  id,
  text,
  countdownSeconds,
  ...rest
}) => {
  let interval
  const { removeNotification } = useAppContext()

  const [remainingSeconds, setRemainingSeconds] = useState<number>(
    countdownSeconds ?? 4
  )

  useEffect(() => {
    setInterval(() => {
      setRemainingSeconds(previousValue => previousValue - 1)
    }, 1000)
  }, [])

  useEffect(() => {
    if (!remainingSeconds) {
      removeNotification(id)
      clearInterval(interval)
    }
  }, [remainingSeconds])

  return (
    <ItemContainer timeout={countdownSeconds ?? 4} {...rest}>
      {text}
      {countdownSeconds && ` (${remainingSeconds})`}
    </ItemContainer>
  )
}

export default NotificationItem
